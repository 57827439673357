// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.About_outerContainer__IDgNU {
  display: flex;
  width: 100%;
}
.About_innerContainer__d5N8A {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px 20px;
}
.About_innerContainer__d5N8A h2 {
  font-size: 20px;
  font-weight: 700;
}

.About_innerContainer__d5N8A p {
  font-size: 18px;
  font-weight: 400;
  color: #555555;
  text-align: justify;
}
.About_image__0NcQE {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .About_outerContainer__IDgNU {
    padding: 0px 10px;
  }
  .About_innerContainer__d5N8A {
    text-align: left;
    margin: 0px 10px;
  }
  .About_innerContainer__d5N8A h2 {
    font-size: 18px;
  }
  .About_innerContainer__d5N8A p {
    font-size: 16px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Home/About.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;AACb;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE;IACE,iBAAiB;EACnB;EACA;IACE,gBAAgB;IAChB,gBAAgB;EAClB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".outerContainer {\n  display: flex;\n  width: 100%;\n}\n.innerContainer {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  margin: 0px 20px;\n}\n.innerContainer h2 {\n  font-size: 20px;\n  font-weight: 700;\n}\n\n.innerContainer p {\n  font-size: 18px;\n  font-weight: 400;\n  color: #555555;\n  text-align: justify;\n}\n.image {\n  width: 70px;\n  height: 70px;\n  border-radius: 50%;\n  margin-bottom: 20px;\n}\n\n@media (max-width: 768px) {\n  .outerContainer {\n    padding: 0px 10px;\n  }\n  .innerContainer {\n    text-align: left;\n    margin: 0px 10px;\n  }\n  .innerContainer h2 {\n    font-size: 18px;\n  }\n  .innerContainer p {\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `About_outerContainer__IDgNU`,
	"innerContainer": `About_innerContainer__d5N8A`,
	"image": `About_image__0NcQE`
};
export default ___CSS_LOADER_EXPORT___;
