// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InnoStore_storeBackground__fjdnO {
  background-color: #020d45;
  width: 100%;
  height: 100vh;
  gap: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  color: white;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 50px;
}

.InnoStore_storeCards__HnH9b {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.InnoStore_storeNav__qJjXp {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
}

.InnoStore_heading__DB8nL {
  font-size: 50px;
  font-weight: 1000;
  margin: 0px 0;
}

@media (max-width: 430px) {
  .InnoStore_storeBackground__fjdnO {
    padding-top: 170px;
  }
  .InnoStore_heading__DB8nL {
    text-align: center;
    font-size: 38px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/InnoStore/InnoStore.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,aAAa;EACb,SAAS;EACT,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,eAAe;EACf,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,SAAS;EACT,eAAe;EACf,uBAAuB;EACvB,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,kBAAkB;IAClB,eAAe;EACjB;AACF","sourcesContent":[".storeBackground {\n  background-color: #020d45;\n  width: 100%;\n  height: 100vh;\n  gap: 50px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding-top: 100px;\n  color: white;\n  overflow: hidden;\n  overflow-y: auto;\n  padding-bottom: 50px;\n}\n\n.storeCards {\n  display: flex;\n  gap: 2rem;\n  flex-wrap: wrap;\n  justify-content: center;\n  width: 100%;\n}\n\n.storeNav {\n  display: flex;\n  gap: 2rem;\n  flex-wrap: wrap;\n  justify-content: center;\n  width: 80%;\n}\n\n.heading {\n  font-size: 50px;\n  font-weight: 1000;\n  margin: 0px 0;\n}\n\n@media (max-width: 430px) {\n  .storeBackground {\n    padding-top: 170px;\n  }\n  .heading {\n    text-align: center;\n    font-size: 38px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"storeBackground": `InnoStore_storeBackground__fjdnO`,
	"storeCards": `InnoStore_storeCards__HnH9b`,
	"storeNav": `InnoStore_storeNav__qJjXp`,
	"heading": `InnoStore_heading__DB8nL`
};
export default ___CSS_LOADER_EXPORT___;
