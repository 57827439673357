import React from "react";
import classes from "./BecomePartner.module.css";

const BecomePartner = () => {
  return (
    <div className={classes.mainContainer}>
      <div className={classes.leftContainer}>
        <div className={classes.heading1Conatiner}>
          <p className={classes.Become}>Become</p>
          <p className={classes.A}>An</p>
          <img
            src="./Images/innovartan_Logo.png"
            alt=""
            className={classes.heading1Image}
          />
        </div>
        <div className={classes.heading2Conatiner}>
          <p className={classes.Become}>Channel</p>
          <p className={classes.A}>Partner</p>
        </div>
        <div className={classes.subHeadingContainer}>
          <div className={classes.subHeadings}>
            <i className={`fa-solid fa-circle-check ${classes.tickIcon}`}></i>
            <p className={classes.subHeading}>
              Earn <strong>1% recurring commission</strong> on the lifetime
              revenue generated by your referrals
            </p>
          </div>
          <div className={classes.subHeadings}>
            <i className={`fa-solid fa-circle-check ${classes.tickIcon}`}></i>
            <p className={classes.subHeading}>
              <strong>Unlimited Earning</strong> Potential
            </p>
          </div>
          <div className={classes.subHeadings}>
            <i className={`fa-solid fa-circle-check ${classes.tickIcon}`}></i>
            <p className={classes.subHeading}>
              Earn <strong>₹10000</strong> on Every Deal
            </p>
          </div>
          <div className={classes.subHeadings}>
            <i className={`fa-solid fa-circle-check ${classes.tickIcon}`}></i>
            <p className={classes.subHeading}>
              Great <strong>After-Sales Support</strong> By{" "}
              <strong style={{ color: "#f46522" }}>Innovartan</strong> Team
            </p>
          </div>
          <div className={classes.subHeadings}>
            <i className={`fa-solid fa-circle-check ${classes.tickIcon}`}></i>
            <p className={classes.subHeading}>
              Join The League of <strong>Innovartan Partners</strong>
            </p>
          </div>
          <a href="tel:9718854686" style={{ alignSelf: "center" }}>
            <img
              src="./Images/ppContact.png"
              alt=""
              className={classes.heading2Image}
            />
          </a>
        </div>
      </div>
      <div className={classes.righContainer}>
        <img
          src="https://innovartan.s3.amazonaws.com/92a6cfa2e7442529a6f4d558a5c5f7a1426340637/f57ec3aa508067c3e598373f672a8321.jpeg"
          alt="Kuldeep Kataria"
          className={classes.partnerProgamImage}
        />
      </div>
    </div>
  );
};

export default BecomePartner;
