// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CategoryIcon_customButton__r0K7Q {
  display: flex;
  width: 85px;
  height: 85px;
  background-color: #785eff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
}

.CategoryIcon_customButton__r0K7Q:hover {
  transform: scale(1.2);
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Home/CategoryIcon.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,4CAA4C;EAC5C,mBAAmB;EACnB,uBAAuB;EACvB,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".customButton {\n  display: flex;\n  width: 85px;\n  height: 85px;\n  background-color: #785eff;\n  border-radius: 50%;\n  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n  align-items: center;\n  justify-content: center;\n  transition: transform 0.3s;\n}\n\n.customButton:hover {\n  transform: scale(1.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customButton": `CategoryIcon_customButton__r0K7Q`
};
export default ___CSS_LOADER_EXPORT___;
