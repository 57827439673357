// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EducatorSwiperCard_educatorImage1__ZiR2X {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.EducatorSwiperCard_educatorImage__ZB-BH {
  width: 200px;
  height: 200px;
  overflow: hidden;
  margin-top: 30px;
  margin-bottom: 10px;
  border-radius: 50%;
  border: 1px solid #f2f2f2;
}

.EducatorSwiperCard_name__BraAa {
  font-size: 24px;
  font-weight: bold;
  margin-top: 0px;
}

.EducatorSwiperCard_designation__UZXK8 {
  font-size: 18px;
  color: #666;
  margin-top: -15px;
}

.EducatorSwiperCard_outerContainer__aFtON {
  display: "flex";
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Home/EducatorSwiperCard.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".educatorImage1 {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}\n.educatorImage {\n  width: 200px;\n  height: 200px;\n  overflow: hidden;\n  margin-top: 30px;\n  margin-bottom: 10px;\n  border-radius: 50%;\n  border: 1px solid #f2f2f2;\n}\n\n.name {\n  font-size: 24px;\n  font-weight: bold;\n  margin-top: 0px;\n}\n\n.designation {\n  font-size: 18px;\n  color: #666;\n  margin-top: -15px;\n}\n\n.outerContainer {\n  display: \"flex\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"educatorImage1": `EducatorSwiperCard_educatorImage1__ZiR2X`,
	"educatorImage": `EducatorSwiperCard_educatorImage__ZB-BH`,
	"name": `EducatorSwiperCard_name__BraAa`,
	"designation": `EducatorSwiperCard_designation__UZXK8`,
	"outerContainer": `EducatorSwiperCard_outerContainer__aFtON`
};
export default ___CSS_LOADER_EXPORT___;
