import React from "react";
import BecomePartner from "./BecomePartner";
import BenefitsJoinning from "./BenefitsJoinning";
import Highlights from "./Highlights";
import BecomeClassplus from "./BecomeClassplus";
import Footer from "../../Footer/Footer";

const PartnerProgram = () => {
  return (
    <div>
      <BecomePartner />
      <BenefitsJoinning />
      <Highlights />
      <BecomeClassplus />
      <Footer />
    </div>
  );
};

export default PartnerProgram;
