import React from "react";

const NoPage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
      }}
    >
      <h1>No Page Found</h1>
    </div>
  );
};

export default NoPage;
