// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TermOfUse_heading__VRHcQ {
    width: 100%;
    height: 100px;
    background-color: #104a9c;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TermOfUse_heading__VRHcQ h1 {
    color: white;
}

.TermOfUse_subContainer__wrv-W {
    display: flex;
    flex-direction: column;
    margin: 80px 170px 80px 170px;
}

@media screen and (max-width: 450px) {
    .TermOfUse_subContainer__wrv-W {
        margin: 50px 30px 50px 30px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/OLD_Components/Pages/TermOfUse/TermOfUse.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,6BAA6B;AACjC;;AAEA;IACI;QACI,2BAA2B;IAC/B;AACJ","sourcesContent":[".heading {\n    width: 100%;\n    height: 100px;\n    background-color: #104a9c;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.heading h1 {\n    color: white;\n}\n\n.subContainer {\n    display: flex;\n    flex-direction: column;\n    margin: 80px 170px 80px 170px;\n}\n\n@media screen and (max-width: 450px) {\n    .subContainer {\n        margin: 50px 30px 50px 30px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `TermOfUse_heading__VRHcQ`,
	"subContainer": `TermOfUse_subContainer__wrv-W`
};
export default ___CSS_LOADER_EXPORT___;
