// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BecomeClassplus_container__VIePj {
  text-align: center;
  padding: 2rem;
  margin: 30px 20px;
}

.BecomeClassplus_BCheading__Tq8eL {
  font-family: "Circular Std";
  font-size: 40px;
  font-weight: 700;
  line-height: 51px;
  align-items: center;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  /* margin-bottom: 3rem; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.BecomeClassplus_BCdesc__aKvvg {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 450;
  font-size: 24px;
  line-height: 36px;
  color: #7c7c82;
  text-align: center;
}

.BecomeClassplus_signupButton__GnXlx {
  font-family: "Circular Std";
  font-style: normal;
  padding: 0.8rem 1.5rem;
  font-size: 1.5rem;
  width: 200px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 2rem;
}

.BecomeClassplus_signupButton__GnXlx:hover {
  background-color: #0056b3;
}

.BecomeClassplus_Become__3sJLv {
  margin-left: 10px;
  margin-bottom: 0px;
  color: #f46522;
}

.BecomeClassplus_A__bxpyL {
  margin-left: 10px;
  color: #114c9f;
  margin-bottom: 0px;
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Partner_Program/BecomeClassplus.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,2BAA2B;EAC3B,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,0BAA0B;EAC1B,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,sBAAsB;EACtB,iBAAiB;EACjB,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".container {\n  text-align: center;\n  padding: 2rem;\n  margin: 30px 20px;\n}\n\n.BCheading {\n  font-family: \"Circular Std\";\n  font-size: 40px;\n  font-weight: 700;\n  line-height: 51px;\n  align-items: center;\n  text-align: center;\n  color: rgba(0, 0, 0, 0.87);\n  /* margin-bottom: 3rem; */\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n}\n\n.BCdesc {\n  font-family: \"Circular Std\";\n  font-style: normal;\n  font-weight: 450;\n  font-size: 24px;\n  line-height: 36px;\n  color: #7c7c82;\n  text-align: center;\n}\n\n.signupButton {\n  font-family: \"Circular Std\";\n  font-style: normal;\n  padding: 0.8rem 1.5rem;\n  font-size: 1.5rem;\n  width: 200px;\n  background-color: #007bff;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  margin-top: 2rem;\n}\n\n.signupButton:hover {\n  background-color: #0056b3;\n}\n\n.Become {\n  margin-left: 10px;\n  margin-bottom: 0px;\n  color: #f46522;\n}\n\n.A {\n  margin-left: 10px;\n  color: #114c9f;\n  margin-bottom: 0px;\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BecomeClassplus_container__VIePj`,
	"BCheading": `BecomeClassplus_BCheading__Tq8eL`,
	"BCdesc": `BecomeClassplus_BCdesc__aKvvg`,
	"signupButton": `BecomeClassplus_signupButton__GnXlx`,
	"Become": `BecomeClassplus_Become__3sJLv`,
	"A": `BecomeClassplus_A__bxpyL`
};
export default ___CSS_LOADER_EXPORT___;
