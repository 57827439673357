// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Teacher_introduction__1NMGe {
  margin-bottom: 50px;
  padding: 0 25px;
}
@media screen and (max-width: 765px) {
  .Teacher_introduction__1NMGe {
    padding: 0 0px;
  }
}
.Teacher_threeCards__EEBaH {
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between; /* Distribute items evenly with space between */
  padding-top: 50px;
  max-width: 90%;
  width: 80%;
  margin: 0 auto;
}

@media screen and (max-width: 765px) {
  .Teacher_threeCards__EEBaH {
    width: 100%;
    flex-direction: column;
  }
}

@media screen and (max-width: 1024px) {
  .Teacher_threeCards__EEBaH {
    aliggn-items: center;
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Teachers/Teacher.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE;IACE,cAAc;EAChB;AACF;AACA;EACE,6BAA6B;EAC7B,aAAa;EACb,8BAA8B,EAAE,+CAA+C;EAC/E,iBAAiB;EACjB,cAAc;EACd,UAAU;EACV,cAAc;AAChB;;AAEA;EACE;IACE,WAAW;IACX,sBAAsB;EACxB;AACF;;AAEA;EACE;IACE,oBAAoB;IACpB,WAAW;EACb;AACF","sourcesContent":[".introduction {\n  margin-bottom: 50px;\n  padding: 0 25px;\n}\n@media screen and (max-width: 765px) {\n  .introduction {\n    padding: 0 0px;\n  }\n}\n.threeCards {\n  border-top: 1px solid #e0e0e0;\n  display: flex;\n  justify-content: space-between; /* Distribute items evenly with space between */\n  padding-top: 50px;\n  max-width: 90%;\n  width: 80%;\n  margin: 0 auto;\n}\n\n@media screen and (max-width: 765px) {\n  .threeCards {\n    width: 100%;\n    flex-direction: column;\n  }\n}\n\n@media screen and (max-width: 1024px) {\n  .threeCards {\n    aliggn-items: center;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"introduction": `Teacher_introduction__1NMGe`,
	"threeCards": `Teacher_threeCards__EEBaH`
};
export default ___CSS_LOADER_EXPORT___;
