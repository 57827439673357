// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SwiperCard_outerContainer__k4FcE {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.SwiperCard_schoolLogo__hOnHc {
  padding: 20px;
}
.SwiperCard_amountContainer__wqS-H {
  position: absolute;
  bottom: 0px;
  right: 50px;
  display: flex;
  width: 100px;
  height: 50px;
  border-radius: 10%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f16126;
}
.SwiperCard_amountContainer__wqS-H h3 {
  color: white;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.SwiperCard_badge__kHJZU {
  width: 150px;
  height: 30px;
  border-radius: 5px;
  background-color: #00a556;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  padding: 5px;
}

.SwiperCard_badge__kHJZU h5 {
  color: white;
  font-size: 16px;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Home/SwiperCard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;AAC3B;AACA;EACE,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,SAAS;AACX","sourcesContent":[".outerContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n}\n\n.schoolLogo {\n  padding: 20px;\n}\n.amountContainer {\n  position: absolute;\n  bottom: 0px;\n  right: 50px;\n  display: flex;\n  width: 100px;\n  height: 50px;\n  border-radius: 10%;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background-color: #f16126;\n}\n.amountContainer h3 {\n  color: white;\n  font-size: 20px;\n  font-weight: 700;\n  margin: 0;\n}\n\n.badge {\n  width: 150px;\n  height: 30px;\n  border-radius: 5px;\n  background-color: #00a556;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 12px;\n  font-weight: 700;\n  padding: 5px;\n}\n\n.badge h5 {\n  color: white;\n  font-size: 16px;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `SwiperCard_outerContainer__k4FcE`,
	"schoolLogo": `SwiperCard_schoolLogo__hOnHc`,
	"amountContainer": `SwiperCard_amountContainer__wqS-H`,
	"badge": `SwiperCard_badge__kHJZU`
};
export default ___CSS_LOADER_EXPORT___;
