// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommonNavbar_outerNavbar__9BfuG {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 100px 0px 100px;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #f8f9fa;
}

@media screen and (max-width: 908px) {
  .CommonNavbar_outerNavbar__9BfuG {
    padding: 0px 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/CommonNavbar/CommonNavbar.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,6BAA6B;EAC7B,gBAAgB;EAChB,MAAM;EACN,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".outerNavbar {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  padding: 10px 100px 0px 100px;\n  position: sticky;\n  top: 0;\n  z-index: 1000;\n  background-color: #f8f9fa;\n}\n\n@media screen and (max-width: 908px) {\n  .outerNavbar {\n    padding: 0px 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerNavbar": `CommonNavbar_outerNavbar__9BfuG`
};
export default ___CSS_LOADER_EXPORT___;
