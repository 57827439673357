// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IntroductionCard_box__3GZLO {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  display: flex;
  padding: 20px;
  width: 320px;
  height: 150px;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  .IntroductionCard_container__lYgTt {
    flex-direction: column;
  }
  .IntroductionCard_box__3GZLO {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Teachers/IntroductionCard.module.css"],"names":[],"mappings":"AAAA;EACE;0CACwC;EACxC,aAAa;EACb,aAAa;EACb,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".box {\n  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,\n    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;\n  display: flex;\n  padding: 20px;\n  width: 320px;\n  height: 150px;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 50px;\n}\n\n@media (max-width: 768px) {\n  .container {\n    flex-direction: column;\n  }\n  .box {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `IntroductionCard_box__3GZLO`,
	"container": `IntroductionCard_container__lYgTt`
};
export default ___CSS_LOADER_EXPORT___;
