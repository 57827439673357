// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MoreThanSchool_section__DDXjN {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 100px;
}

@media (max-width: 425px) {
  .MoreThanSchool_section__DDXjN {
    padding: 30px 20px;
  }
}

.MoreThanSchool_heading__OUS4O {
  color: #00000;
  text-align: center;
  font: 700 40px/52px Barlow, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
  .MoreThanSchool_heading__OUS4O {
    max-width: 100%;
  }
}

.MoreThanSchool_highlight__Fk6Xi {
  color: rgb(245, 101, 32);
}

.MoreThanSchool_description__Mv4pY {
  color: #555;
  text-align: center;
  margin-top: 20px;
  font: 400 18px/24px Barlow, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
  .MoreThanSchool_description__Mv4pY {
    width: 100%;
  }
}

.MoreThanSchool_imageContainer__WwAXw {
  display: flex;
  width: 100%;
  justify-content: center;
}

.MoreThanSchool_backgroundImage__EKdN2 {
  width: 80%;
  object-fit: contain;
}

@media (max-width: 425px) {
  .MoreThanSchool_backgroundImage__EKdN2 {
    width: 100%;
    object-fit: cover;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/School/MoreThanSchool.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,wEAAwE;AAC1E;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,wEAAwE;AAC1E;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE,aAAa;EACb,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE;IACE,WAAW;IACX,iBAAiB;EACnB;AACF","sourcesContent":[".section {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 50px 100px;\n}\n\n@media (max-width: 425px) {\n  .section {\n    padding: 30px 20px;\n  }\n}\n\n.heading {\n  color: #00000;\n  text-align: center;\n  font: 700 40px/52px Barlow, -apple-system, Roboto, Helvetica, sans-serif;\n}\n\n@media (max-width: 991px) {\n  .heading {\n    max-width: 100%;\n  }\n}\n\n.highlight {\n  color: rgb(245, 101, 32);\n}\n\n.description {\n  color: #555;\n  text-align: center;\n  margin-top: 20px;\n  font: 400 18px/24px Barlow, -apple-system, Roboto, Helvetica, sans-serif;\n}\n\n@media (max-width: 991px) {\n  .description {\n    width: 100%;\n  }\n}\n\n.imageContainer {\n  display: flex;\n  width: 100%;\n  justify-content: center;\n}\n\n.backgroundImage {\n  width: 80%;\n  object-fit: contain;\n}\n\n@media (max-width: 425px) {\n  .backgroundImage {\n    width: 100%;\n    object-fit: cover;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `MoreThanSchool_section__DDXjN`,
	"heading": `MoreThanSchool_heading__OUS4O`,
	"highlight": `MoreThanSchool_highlight__Fk6Xi`,
	"description": `MoreThanSchool_description__Mv4pY`,
	"imageContainer": `MoreThanSchool_imageContainer__WwAXw`,
	"backgroundImage": `MoreThanSchool_backgroundImage__EKdN2`
};
export default ___CSS_LOADER_EXPORT___;
